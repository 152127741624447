import React from "react"

export const VideosData = [
  {
    videoIframe: (
      <iframe
        src="https://www.youtube.com/embed/3nl0C2tuLvw"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        title="Html-text"
      ></iframe>
    ),
    title: "HTML Text",
    desc: "All you need to know about text in HTML.",
  },
  {
    videoIframe: (
      <iframe
        src="https://www.youtube.com/embed/tlXx4b51NLo"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        title="styling-layout"
      ></iframe>
    ),
    title: "Styling Layout",
    desc: "The basics on how to style layout with CSS.",
  },
  {
    videoIframe: (
      <iframe
        src="https://www.youtube.com/embed/DTBv9WL7Za4"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        title="responsive-navbar"
      ></iframe>
    ),
    title: "Responsive Navbar",
    desc: "This video will teach you how to make a responsive navbar.",
  },
]
