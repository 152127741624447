import React from "react"
import styled from "styled-components"
import { VideosData } from "../../data/VideosData"

import { motion } from "framer-motion"

const Videos = () => {
  return (
    <VideoContainer>
      <Heading
        initial={{ opacity: 0, y: -250 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 1 }}
      >
        Some Videos From My Channel
      </Heading>
      <Wrapper>
        {VideosData.map((item, index) => {
          return (
            <VideosBox key={index}>
              <VideoFrame
                initial={{ opacity: 0, x: 250 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ duration: 1, delay: 0.5 }}
              >
                {item.videoIframe}
              </VideoFrame>
              <Title
                initial={{ opacity: 0, x: -250 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ duration: 1, delay: 1 }}
              >
                {item.title}
              </Title>
              <Description
                initial={{ opacity: 0, x: -250 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ duration: 1, delay: 1.5 }}
              >
                {item.desc}
              </Description>
            </VideosBox>
          )
        })}
      </Wrapper>
    </VideoContainer>
  )
}

export default Videos

const VideoContainer = styled.div`
  width: 100%;
  background: #fff;
  color: #000;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 4rem calc((100vw - 1300px) / 2);
`

const Heading = styled(motion.h1)`
  text-align: start;
  font-size: clamp(1.5rem, 5vw, 2em);
  margin-bottom: 3rem;
  padding: 0 2rem;
`

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 10px;

  @media screen and (max-width: 1180px) {
    grid-template-columns: 1fr;
  }

  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr;
  }

  @media screen and (max-width: 500px) {
    grid-template-columns: 1fr;
  }
`

const VideosBox = styled.div`
  height: 100%;
  width: 100%;
  padding: 2rem;
`

const VideoFrame = styled(motion.div)`
  font-size: 3em;
  margin-bottom: 1rem;
`

const Title = styled(motion.p)`
  font-size: clamp(1rem, 2.5vw, 1.5rem);
  margin-bottom: 0.5rem;
`

const Description = styled(motion.p)`
  font-size: 1.5rem;
`
