import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import Videos from "../components/VideoPart/Videos"

const VideosPage = () => (
  <Layout>
    <SEO title="Videos" />
    <Videos />
  </Layout>
)

export default VideosPage
